/**
* Main validation form
* @param {form} jquery obj - Form
* @param {options} obj - object width params
*/
function validate(form, options){
    var setings = {
        errorFunction:null,
        submitFunction:null,
        highlightFunction:null,
        unhighlightFunction:null,
        captcha:null,
        captchaExist:false
    }
    $.extend(setings, options);

    var $form;
    if (form == '$(this)'){
        $form = form;
    } else {
        $form = $(form);
    }

    if ($form.length && $form.attr('novalidate') === undefined) {
        $form.on('submit', function(e) {
            e.preventDefault();
        });

        $form.validate({
            errorClass : 'errorText',
            focusCleanup : true,
            focusInvalid : false,
            invalidHandler: function(event, validator) {
                if(typeof(setings.errorFunction) === 'function'){
                    setings.errorFunction(form);
                }
            },
            errorPlacement: function(error, element) {
                error.appendTo( element.closest('.form_input'));
            },
            highlight: function(element, errorClass, validClass) {
                $(element).addClass('error');
                $(element).closest('.form_row').addClass('error').removeClass('valid');
                if( typeof(setings.highlightFunction) === 'function' ) {
                    setings.highlightFunction(form);
                }
            },
            unhighlight: function(element, errorClass, validClass) {
                $(element).removeClass('error');
                if($(element).closest('.form_row').is('.error')){
                    $(element).closest('.form_row').removeClass('error').addClass('valid');
                }
                if( typeof(setings.unhighlightFunction) === 'function' ) {
                    setings.unhighlightFunction(form);
                }
            },
            submitHandler: function(form) {
                // лютый captcha-костыль
                if ( setings.captchaExist){
                    if(captchaGlobal){
                        $('.g-recaptcha').removeClass('error');

                        if( typeof(setings.submitFunction) === 'function' ) {
                            setings.submitFunction(form);
                        } else {
                            $form[0].submit();
                        }
                    }else {
                        $('.g-recaptcha').addClass('error');
                    }
                }else {

                    if( typeof(setings.submitFunction) === 'function' ) {
                        setings.submitFunction(form);
                    } else {
                        $form[0].submit();
                    }
                }

            }
        });

        $('[required]',$form).each(function(){
            $(this).rules( "add", {
                required: true,
                messages: {
                    required: "Вы пропустили"
                }
            });
        });

        if($('[type="email"]',$form).length) {
            $('[type="email"]',$form).rules( "add",
            {
                messages: {
                    email: "Невалидный email"
                 }
            });
        }

        if($('.tel-mask[required]',$form).length){
            $('.tel-mask[required]',$form).rules("add",
            {
                messages:{
                    required:"Введите номер мобильного телефона."
                }
            });
        }

        $('[type="password"]',$form).each(function(){
            if($(this).is("#re_password") == true){
                $(this).rules("add", {
                    minlength:3,
                    equalTo:"#password",
                    messages:{
                        equalTo:"Неверный пароль.",
                        minlength:"Недостаточно символов."
                    }
                });
            }
        })
    }
}


/**
* Sending form with a call popup
* @param {form} string - Form
*/
function validationCall(form){

  var thisForm = $(form);
  var formSur = thisForm.serialize();

    $.ajax({
        url : thisForm.attr('action'),
        data: formSur,
        method:'POST',
        success : function(data){
            if ( data.trim() == 'true') {
                thisForm.trigger("reset");
                $.fancybox.close();
                popNext("#call_success", "call-popup");
            }
            else {
               thisForm.trigger('reset');
            }

        }
    });

}


/**
* Sending form with a call popup
* @param {popupId} string - Id form, that we show
* @param {popupWrap} string - Name of class, for wrapping popup width form
*/
function popNext(popupId, popupWrap){

     $.fancybox.open({
        src  : popupId,
        type : '',
        opts : {
            baseClass: popupWrap || '',
            afterClose: function () {
                $('form').trigger("reset");
                clearTimeout(timer);
            }
        }
    });

    var timer = null;

    timer = setTimeout(function () {
        $('form').trigger("reset");
        $.fancybox.close();
    }, 2000);

}


/**
* Mask on input(russian telephone)
*/
function Maskedinput(){
    if($('.tel-mask')){
        //$('.tel-mask').mask('+9 (999) 999-99-99 ');
        $('.tel-mask').keydown(function (evt) {
            var charCode = (evt.which) ? evt.which : event.keyCode
            if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57) & charCode != 187)
                return false;
            return true;
        })

    }
}


/**
* Fansybox on form
*/
function fancyboxForm(){
    $('.fancybox-form').fancybox({
        baseClass: 'fancybox-form'
    })
}

var captchaGlobal = false;
function contactDetailFormCaptchaCallback(response){
    captchaGlobal = response;
    $('.g-recaptcha').removeClass('error');
}

function contactDetailFormCaptchaExpiredCallback(expired){
    captchaGlobal = false;
}

function contactDetailFormCaptchaErrorCallback(err){
    $('.g-recaptcha').addClass('error');
}


//comments
function submitCommentForm(form){
    var thisForm = $(form);
    var formSur = thisForm.serialize();

    $.ajax({
        url : thisForm.attr('action'),
        data: formSur,
        method:'POST',
        dataType:'json',
        success:function(data){
            if (!data.error && Object.keys(data).length){

                setComment(data.commentData);
                resetFormAndComments()
            }else if (data.error){

                let errorMessage = data.message;
                alert(errorMessage);
                resetFormAndComments()
            }
        },
        error:function(err){
            alert('Произошли неполадки при отправке комментария.');
            resetFormAndComments();
        }
    });

    function resetFormAndComments(){
        thisForm.trigger('reset');
        thisForm.find('.answer-comment-id').val('');
        $('.js-comment-button.active').removeClass('active');
    }

}

function setComment(commentData){
    const {commentId = '',commentText,commentAuthourName,commentDate,answeredCommentId} = commentData;

    let commentTemplate = $($('#comments-template').html());

    commentTemplate.find('.js-comment-author').text(commentAuthourName);
    commentTemplate.find('.js-comment-data p').text(commentText);
    commentTemplate.find('.js-comment-button').attr('data-id',commentId);
    if(commentDate.length){
        commentTemplate.find('.comment-date').text(commentDate);
    }

    if(answeredCommentId){
        const appendToHolder = $('.comments-holder .js-comment-button[data-id="'+answeredCommentId+'"]').closest('.js-comments-item');

        commentTemplate.appendTo(appendToHolder).slideDown();

    }else{
        commentTemplate.appendTo( $('.comments-holder')).slideDown();
    }
}

function addCommentToComment(){
    let mainForm = $('.js-comments-form');
    $(document).on('click','.js-comment-button',(e)=>{
        //e.preventDefault();
        let target = $(e.currentTarget);
        let commentId = target.data('id');

        if (target.hasClass('active')){
            //set comment id empty
            mainForm.find('.answer-comment-id').val('');
            target.removeClass('active');
        }else {
            if(commentId){
                target.addClass('active');

                let name = target.siblings('.comment-top').find('.js-comment-author').text();

                //set comment id
                mainForm.find('.answer-comment-id').val(commentId);

                let textarea = mainForm.find('textarea');
                textarea.val('').val(name+' , \n');

                scrollToForm(e,textarea);
            }

        }
    })

    function scrollToForm(e,textarea){
        let mainFormScrollTop = mainForm.offset().top + mainForm.height();
        let windowScroll = $(window).scrollTop();
        if(windowScroll < mainFormScrollTop){

            let scrolTo = mainForm.offset().top - $(window).height() +  mainForm.height();

            $('body,html').stop().animate({scrollTop:scrolTo+30},500,()=>{
                textarea.focus();
            });
            e.preventDefault();
        }
    }

}

const generateSpecHiddenInput = () => {
    let form = $('form');
    if(form){
        form.append('<input type="hidden" name="specInput" value="hello" class="special-hidden-input"/>')
    }
}

$(document).ready(function(){

   validate('#call-popup form', {submitFunction:validationCall});
   validate('#call-popup-congratulation form', {submitFunction:validationCall});
   validate('.js-index-organizate-contact-form', {submitFunction:validationCall});

   validate('.js-contact-detail-form', {submitFunction:validationCall,captcha:captchaGlobal,captchaExist:true});

   validate('.js-comments-form', {submitFunction:submitCommentForm});

   validate('.contact-form', {submitFunction:validationCall});

   validate('.search_form');
   Maskedinput();
   fancyboxForm();

   addCommentToComment();
   generateSpecHiddenInput();
});